/* GENERAL STYLES */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body, html {
    height: 100%;
    font-family: "Poppins", "sans-serif";
    scroll-behavior: smooth;
    background: linear-gradient(rgba(10, 31, 50, 0.85), rgba(10, 31, 50, 0.85));
}

a {
    color: white;
    text-decoration: none;
    text-decoration-color: black;
    border-radius: 50px;
    /* margin-top: ; */
}

.logo {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.logo-text {
    color: white;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    font-weight: bold;
}

.logo-script {
    font-family: "Playwrite IT Moderna", cursive;
    font-optical-sizing: auto;
    font-size: 1.1rem;
    color: #D15A44;
}

.logo-solutions {
    font-family: "Playwrite IT Moderna";
    font-optical-sizing: auto;
    font-size: 1.1rem;
    color: #5aa2cc;
}

.logo img {
    max-height: 8vh;
    margin: 10px 0;
    /* padding-top: ; */
}

/* nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 9vh;
    padding: 0 1.5rem;
    background-color: black;
    position: relative;
} */

/* .nav-links ul li a {
    color: #191f24;
    text-decoration: none;
    transition: color 0.5s;
} */
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    padding: 0 1.5rem;
    background-color: rgb(0, 0, 0);
    opacity: .9;
    position: sticky;
    /* Make it sticky */
    top: 0;
    /* Sticks to the top */
    z-index: 1000;
    /* Ensure it stays above other elements */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional shadow for depth */
}

/* Navbar Links */
.nav-links ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1.2rem;
    list-style: none;
    font-size: 1.2rem;
    color: white;
}

.nav-links {
    display: flex;
    gap: 1.1rem;
    list-style: none;
    font-size: 1.2rem;
    color: #1D1E2A;
}



.nav-links ul li {
    display: inline;
}

.nav-links ul li a {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.nav-links ul li a:hover {
    background-color: rgba(209, 89, 68, 0.359);
    /* Subtle background on hover */
    color: #D15A44;
}

.nav-links a:hover {
    color: rgb(184, 78, 46);
    text-decoration: underline;
    text-underline-offset: .5rem;
    transition: all .15s linear;
}

.hamburger {
    display: none;
    font-size: 2rem;
    background: none;
    border: none;
    color: white;
}

.mobile-nav {
    display: none;
    position: absolute;
    max-height: 0;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #2d445f;
    z-index: 1;
    transition: max-height 0.8s ease;
}

.mobile-nav.open {
    display: block;
    max-height: 500px;
    text-align: center;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .nav-links {
        display: none;
        flex-direction: column;
    }

    .nav-links.open {
        display: block;
        background-color: black;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 1;
    }

    .nav-links li {
        padding: 1rem;
        border-bottom: 1px solid #fff;
    }

    .mobile-nav {
        display: none;
    }

    .mobile-nav.open {
        display: block;
    }
}

/* Hero Section (example) */
.hero {
    position: relative;
    z-index: 0;
}